<template>
  <div class="content-layout">
    <div class="select-container">
      <a-button v-if="$hasPermissions(['rcs:cpu_hard_version:create'])" type="primary" @click="addCpuHardware"
        >新增
      </a-button>
      <div>
        <a-select
          style="width: 150px; margin-right: 10px"
          v-model="modelSelect"
          placeholder="请选择中控型号"
          allow-clear
          show-search
        >
          <a-select-option v-for="model in modelList" :key="model.model" :value="model.model">{{
            model.model
          }}</a-select-option>
        </a-select>
        <a-select style="width: 150px" v-model="searchOption">
          <a-select-option
            v-for="option in this.$constRepository.cpuHardware.selectOption"
            :key="option.value"
            :value="option.value"
            >{{ option.label }}</a-select-option
          >
        </a-select>
        <a-input
          v-model="searchKeyWords"
          placeholder="请输入关键字"
          style="width: 200px"
          @keyup.enter="doSearch"
          allow-clear
        />
        <a-button type="primary" style="margin-left: 10px" @click="doSearch">搜索</a-button>
      </div>
    </div>
    <div class="table-container">
      <a-table
        :columns="this.$constRepository.cpuHardware.columns"
        :data-source="tableData"
        :pagination="false"
        rowKey="id"
        @change="tableChange"
      >
        <template slot="lastModificationTime" slot-scope="scope">
          {{ scope | parseTime | orNA }}
        </template>
        <template slot="action" slot-scope="scope">
          <div>
            <a-button
              v-if="$hasPermissions(['rcs:cpu_hard_version:update'])"
              class="green-btn"
              ghost
              @click="editHandler(scope)"
              >编辑</a-button
            >
            <a-divider type="vertical" />
            <a-button
              v-if="$hasPermissions(['rcs:cpu_hard_version:get'])"
              class="green-btn"
              ghost
              @click="detailHandler(scope)"
              >详情</a-button
            >
            <a-button v-if="$hasPermissions(['rcs:dll_file:list'])" type="link" @click="showDynamicModal(scope)"
              >关联动态库</a-button
            >
            <a-divider type="vertical" />
            <a-popconfirm okText="是" cancelText="否" title="是否删除中控硬件版本?" @confirm="delHandler(scope)">
              <a-button v-if="$hasPermissions(['rcs:cpu_hard_version:delete'])" class="red-btn" ghost>删除</a-button>
            </a-popconfirm>
          </div>
        </template>
      </a-table>
      <div class="pagination">
        <a-pagination
          :current="pageInfo.page + 1"
          :show-total="(total) => `共 ${pageInfo.totalElements} 条`"
          show-quick-jumper
          show-size-changer
          :total="pageInfo.totalElements"
          @showSizeChange="onShowSizeChange"
          @change="onChange"
          :pageSizeOptions="['10', '20', '30', '40']"
        />
        <br />
      </div>
    </div>
    <cpu-hardware-add-or-edit-modal
      v-if="addOrEditVisible"
      :visible.sync="addOrEditVisible"
      :item="currentItem"
      @updateView="getList"
    />
    <cpu-hardware-detail-modal v-if="detailVisible" :visible.sync="detailVisible" :item="currentItem" />
    <CpuHardwareDynamicDepotModal ref="cpuHardwareDynamicDepotModal" />
  </div>
</template>
<script>
import lodash from 'lodash';
import CpuHardwareAddOrEditModal from './components/CpuHardwareAddOrEditModal';
import CpuHardwareDetailModal from './components/CpuHardwareDetailModal';
import CpuHardwareDynamicDepotModal from './components/CpuHardwareDynamicDepotModal';

export default {
  name: 'cpuHardware',
  components: { CpuHardwareAddOrEditModal, CpuHardwareDetailModal, CpuHardwareDynamicDepotModal },
  data() {
    return {
      searchKeyWords: '',
      modelSelect: undefined,
      searchOption: this.$constRepository.cpuHardware.optionEnum.HARDVERSION,
      addOrEditVisible: false,
      detailVisible: false,
      tableData: [],
      pageInfo: {
        page: 0,
        size: 10,
        totalElements: 0,
        totalPages: 0,
      },
      currentItem: undefined,
      modelList: [],
      order: '',
      orderField: '',
    };
  },
  methods: {
    /** 新增 */
    addCpuHardware() {
      this.currentItem = undefined;
      this.addOrEditVisible = true;
    },
    /** 编辑 */
    editHandler(scope) {
      this.currentItem = lodash.cloneDeep(scope);
      this.addOrEditVisible = true;
    },
    /** 详情 */
    detailHandler(scope) {
      this.currentItem = scope;
      this.detailVisible = true;
    },
    /** 删除 */
    async delHandler(scope) {
      await this.$apiManager.cpuHardware.delCpuHardware(scope);
      this.$message.success('删除成功');
      this.getList();
    },
    doSearch() {
      this.pageInfo.page = 0;
      this.getList();
    },
    async listHardWare() {
      let payload = {
        page: this.pageInfo.page,
        size: this.pageInfo.size,
      };
      if (this.modelSelect) {
        payload = { ...payload, model: this.modelSelect === '-' ? '\\-' : this.modelSelect };
      } else {
        payload = { ...payload, model: '-' };
      }
      if (this.searchKeyWords !== '') {
        if (this.searchOption === this.$constRepository.cpuHardware.optionEnum.HARDVERSION) {
          payload = { ...payload, hardVersion: this.searchKeyWords };
        }
        if (this.searchOption === this.$constRepository.cpuHardware.optionEnum.REMARK) {
          payload = { ...payload, remark: this.searchKeyWords };
        }
      }
      if (this.order && this.orderField) {
        payload = { ...payload, orderBy: `${this.orderField} ${this.order}` };
      }
      const res = await this.$apiManager.cpuHardware.getCpuHardwareList({ ...payload });
      this.tableData = res.data.elements;
      this.$set(this.pageInfo, 'totalElements', res.data.totalElements);
      this.$set(this.pageInfo, 'totalPages', res.data.totalPages);
      this.$set(this.pageInfo, 'currentPage', res.data.currentPage);
    },
    /** 获取列表 */
    async getList() {
      await this.listHardWare();
      if (this.pageInfo.currentPage + 1 > this.pageInfo.totalPages && this.pageInfo.currentPage) {
        this.pageInfo.page = 0;
        await this.listHardWare();
      }
    },
    onShowSizeChange(current, pageSize) {
      this.pageInfo.page = 0;
      this.$set(this.pageInfo, 'size', pageSize);
      this.getList();
    },
    onChange(pageNumber) {
      this.$set(this.pageInfo, 'page', pageNumber - 1);
      this.getList();
    },
    /** 获取型号列表 */
    async getModelList() {
      const modelRes = await this.$apiManager.cpuModel.getCpuModelList({ unpaged: true });
      this.modelList = modelRes.data.elements;
    },
    tableChange(p, f, s) {
      if (s.order) {
        this.order = s.order === 'descend' ? 'desc' : 'asc';
        this.orderField = s.field;
      } else {
        this.order = '';
        this.orderField = '';
      }
      this.getList();
    },
    showDynamicModal(record) {
      this.$refs.cpuHardwareDynamicDepotModal.showDialog({
        model: record.model,
        hardVersion: record.hardVersion,
      });
    },
  },
  mounted() {
    this.getList();
    this.getModelList();
  },
};
</script>
<style lang="less" scoped>
.content-layout {
  padding: 20px 10px;

  .select-container {
    display: flex;
    justify-content: space-between;
  }

  .table-container {
    .green-btn {
      color: green;
      border: none;
    }

    .red-btn {
      border: none;
      color: red;
    }

    .pagination {
      margin-top: 3%;
    }
  }
}
</style>
